import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography } from '@material-ui/core';
import { AppContext } from '../../App';
import { passwordRequirementsText, validators } from '../../utils';
import AuthPageTemplate from '../../components/auth/AuthPageTemplate';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ConfirmAccountPage = () => {
  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [error, setError] = useState('');
  const classes = useStyles();
  const { dispatch } = useContext(AppContext);
  const handleChange = (input: string) => (e: { target: { value: any; }; }) => {
    setForm({
      ...form,
      [input]: e.target.value,
    });
  };

  const handleError = () =>
    setError('Error confirming account. Please try again.');

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    try {
      e.preventDefault();
      const { password } = form;
      
      if (password === '200') {
        
        dispatch({
          type: 'LOGIN',
    
        });
      } else {
        handleError();
      }
    } catch (err) {
      handleError();
    }
  };

  const formIsOkay =
    form.password.length > 0 &&
    form.passwordConfirmation.length > 0 &&
    validators.isAcceptablePassword(form.password) &&
    form.password === form.passwordConfirmation;

  return (
    <AuthPageTemplate title='Confirm account'>
      <Typography color='textSecondary' variant='caption' gutterBottom>
        {passwordRequirementsText}
      </Typography>
      <TextField
        autoComplete='new-password'
        variant='outlined'
        margin='normal'
        required
        fullWidth
        error={
          form.password.length > 0 &&
          !validators.isAcceptablePassword(form.password)
        }
        type='password'
        id='password'
        label='New password'
        name='password'
        value={form.password}
        onChange={handleChange('password')}
      />
      <TextField
        autoComplete='new-password'
        variant='outlined'
        margin='normal'
        required
        fullWidth
        error={
          form.passwordConfirmation.length > 0 &&
          form.password !== form.passwordConfirmation
        }
        type='password'
        id='passwordConfirmation'
        label='Confirm new password'
        name='passwordConfirmation'
        value={form.passwordConfirmation}
        onChange={handleChange('passwordConfirmation')}
      />
      <Button
        disabled={!formIsOkay}
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
        onClick={handleSubmit}
      >
        {'Submit'}
      </Button>
      {error && (
        <Typography align='center' variant='body2' color='error'>
          {error}
        </Typography>
      )}
    </AuthPageTemplate>
  );
};

export default ConfirmAccountPage;
