import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@material-ui/core';
import WhatsThere from '../assets/WhatsThere1.png';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

// constants
import { APP_TITLE, PAGE_TITLE_HOME, whatsThereAbstract } from '../utils/constants';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    logo: {
      maxHeight: '400px',
      marginBottom: '16px',
    },
    paper: {
      margin: theme.spacing(1, 1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

const Home: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_HOME} | {APP_TITLE}
        </title>
      </Helmet>
      <div className={classes.paper}>
      <Grid item xs={6}>
        <img src={WhatsThere} alt='What is There' className={classes.logo} />
          <Typography component='h3' variant='body2' color='textSecondary'>
            {whatsThereAbstract}
          </Typography>
        </Grid>
      </div>
    </>
  );
};

export default Home;
