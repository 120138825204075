/* eslint-disable no-console */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography } from '@material-ui/core';
import { forgotPasswordInstructions, validators } from '../../utils';
import AuthPageTemplate from '../../components/auth/AuthPageTemplate';


const useStyles = makeStyles((theme) => ({
  email: {
    marginTop: 25,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmail(e.target.value);
  };

  const handleError = () => {
    setError('Sorry, we were unable to find an account for that address.');
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    try {
      e.preventDefault();
 
      setSubmitted(true);
    } catch (err) {
      console.error('err', err);
      handleError();
    }
  };

  return (
    <AuthPageTemplate title='Forgot password'>
      <Typography align='center' variant='body2' color='textSecondary'>
        {forgotPasswordInstructions}
      </Typography>
      <TextField
        className={classes.email}
        variant='outlined'
        margin='normal'
        error={validators.emailError(email)}
        required
        fullWidth
        id='email'
        label='Email address'
        name='email'
        autoComplete='email'
        autoFocus
        value={email}
        onChange={handleChange}
      />
      <Button
        disabled={
          email.length === 0 || validators.emailError(email) || submitted
        }
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
        onClick={handleSubmit}
      >
        {'Submit'}
      </Button>
      {error && (
        <Typography align='center' variant='body2' color='error'>
          {error}
        </Typography>
      )}
    </AuthPageTemplate>
  );
};

export default ForgotPasswordPage;
