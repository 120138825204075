import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UnauthRouter from '../routers/UnAuthRouter';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '70vh',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
  },
}));

export default function UnauthenticatedApp() {
  const classes = useStyles();

  return (
    <div data-testid={'unauthenticatedApp'} className={classes.root}>
      <main className={classes.content}>
        <UnauthRouter />
      </main>
    </div>
  );
}
