// icons
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import AboutIcon from '@material-ui/icons/InfoOutlined';
import ContactIcon from '@material-ui/icons/ContactPhoneOutlined';

// components
import Home from '../pages/Home';
import { SignInPage } from '../pages/auth/';
import About from '../pages/About';
import Contact from '../pages/Contact';

// interface
import RouteItem from '../model/RouteItem.model';

// define app routes
export const routes: Array<RouteItem> = [
  {
    key: 'router-home',
    title: 'Home',
    tooltip: 'Home',
    path: '/',
    enabled: true,
    component: Home,
    icon: HomeIcon,
    appendDivider: true,
  },
  {
    key: 'router-signin',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    path: '/signin',
    enabled: true,
    component: SignInPage,
    icon: DashboardIcon,
  },
  {
    key: 'router-about',
    title: 'About',
    tooltip: 'About',
    path: '/about',
    enabled: true,
    component: About,
    icon: AboutIcon,
    appendDivider: true,
  },
  {
    key: 'router-contact',
    title: 'Contact',
    tooltip: 'Contact',
    path: '/contact',
    enabled: true,
    component: Contact,
    icon: ContactIcon,
  },
];
