import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { AppContext } from '../../App';
import AuthPageTemplate from '../../components/auth/AuthPageTemplate';
// constants
import { APP_TITLE, PAGE_TITLE_DASHBOARD } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  error: {
    color: 'red',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: '',
  });

  const { dispatch } = useContext(AppContext);

  const handleChange = (e: { target: { id: any; value: any; }; }) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleError = () => {
    setState((prevState) => ({
      ...prevState,
      error: 'Invalid email or password.',
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    try {
      e.preventDefault();
      const { email, password } = state;
      if (email.length && password.length) {
        dispatch({
          type: 'LOGIN',
        });
        
        } else {
          handleError();
        }
    } catch (err) {
      handleError();
    }
  };

  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_DASHBOARD} | {APP_TITLE}
        </title>
      </Helmet>
    <AuthPageTemplate title='Sign in'>
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='email'
        label='Email'
        name='email'
        autoComplete='email'
        autoFocus
        value={state.email}
        onChange={handleChange}
      />
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Password'
        type='password'
        id='password'
        autoComplete='current-password'
        value={state.password}
        onChange={handleChange}
      />
      {state.error ? (
        <div className={classes.error}>{state.error}</div>
      ) : (
        <FormControlLabel
          control={<Checkbox value='remember' color='primary' />}
          label='Remember me'
        />
      )}
      <Button
        disabled={state.email.length === 0 || state.password.length === 0}
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
        onClick={handleSubmit}
      >
        Sign In
      </Button>
      <Grid container>
        <Grid item xs>
          <Link to='/forgot/' className={classes.link}>
            {'Forgot password?'}
          </Link>
        </Grid>
      </Grid>
    </AuthPageTemplate>
    </>
  );
};

export default SignIn;
