export const forgotPasswordInstructions =
  'Please submit your email address and then check your inbox for a link to reset your password.';
export const passwordRequirementsText =
  'Your new password should be at least 8 characters and include at least one lowercase letter, an uppercase letter, a number and a symbol.';
export const whatsThereAbstract =
  'Pointing-based search enables people to use their smartphone or smartglasses like a “TV Remote" for the real world and ask questions such as “What is there?”';
export const whatsTherePointingBasedSearch =
  'Pointing-based search enables users to interact with their environment in a simple and intuitive way, just by pointing at the object of interest and asking questions like “What is there?” For this simple mechanism to work, a process needs to be defined that accurately and reliably identifies the object of interest. This process involves the accurate sensing of the user’s current position and the pointing direction on one side, and the collection and mapping of real world phenomena from the real world into a virtual representation (or model) on a server, where the identification takes place, on the other side.';

  // APP TEXT
export const APP_TITLE = "What's There"
export const FOOTER_TEXT = `${new Date().getFullYear()} What's There LLC`
// PAGES TITLE
export const PAGE_TITLE_HOME = "Home"
export const PAGE_TITLE_DASHBOARD = "Dashboard"
export const PAGE_TITLE_ABOUT = "About"
export const PAGE_TITLE_CONTACT = "Contact"
// UI CONSTANTS
export const FOOTER_HEIGHT = 30
export const HEADER_HEIGHT = 60
export const DRAWER_WIDTH = 250

