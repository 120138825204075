import React, { Dispatch, useReducer } from "react";
import {
  createMuiTheme,
  Theme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { UnauthenticatedApp } from './apps/';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
import Layout from "./components/navigation/Layout";

// theme
import { lightTheme, darkTheme } from "./theme/appTheme";

// constants
import { APP_TITLE } from "./utils/constants";

// define app context

const initialState = {
};
enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type IState = {
}

interface IAction {
  type: ActionType;
}

const reducer: React.Reducer<IState, IAction> = (state, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
      };
    case ActionType.LOGOUT:
      return {
      };
    default:
      return state;
  }
};

export const AppContext = React.createContext<{
  state: IState;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

function App() {
  const [useDefaultTheme, toggle] = useReducer((theme) => !theme, true);
  const [state, dispatch] = useReducer(reducer, initialState);
  // define custom theme
  let theme: Theme = createMuiTheme(useDefaultTheme ? lightTheme : darkTheme);
  theme = responsiveFontSizes(theme);

  let appToRender = <UnauthenticatedApp />;

  return (
    <>
      <Helmet>
        <title>{APP_TITLE}</title>
      </Helmet>
      <AppContext.Provider value={{ state, dispatch }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Layout toggleTheme={toggle} useDefaultTheme={useDefaultTheme}>
              {appToRender}
              </Layout>
            </Switch>
          </Router>
        </ThemeProvider>
      </AppContext.Provider>
    </>
  );
}

export  default App;