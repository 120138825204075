import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ForgotPasswordPage,
  ResetPasswordPage,
  SignInPage,
  VerifyAccountPage,
} from '../pages/auth';
import {
  AboutPage,
  ContactPage,
  HomePage,
} from '../pages';

const UnauthRouter = () => (
  <>
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/signin/' component={SignInPage} />
      <Route exact path='/confirm/' component={VerifyAccountPage} />
      <Route exact path='/forgot/' component={ForgotPasswordPage} />
      <Route exact path='/reset/' component={ResetPasswordPage} />
      <Route exact path='/contact/' component={ContactPage} />
      <Route exact path='/about/' component={AboutPage} />
      <Route render={() => <Redirect to='/' />} />
    </Switch>
  </>
);

export default UnauthRouter;
