import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Grid, Paper, Typography } from '@material-ui/core';
import logo from '../../assets/logo.png';
import AuthImage from '../../assets/sign-in.jpeg';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  image: {
    backgroundImage: `url(${AuthImage})`, // image comes from: https://source.unsplash.com/0ou4CVSoINY
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  logo: {
    maxHeight: '150px',
    marginBottom: '16px',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    height: '70vh',
  },
}));

// define interface to represent component props
interface Props {
  children: ReactNode;
  title: string;
}

const AuthPageTemplate: FC<Props> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt='Logo' className={classes.logo} />
          <Typography component='h3' variant='h6'>
            {title}
          </Typography>
          <form className={classes.form} noValidate>
            {children}
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default AuthPageTemplate;
