import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@material-ui/core';
import WhatsThereRevised from '../assets/WhatsThere_Revised.png';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// constants
import {
  APP_TITLE,
  PAGE_TITLE_ABOUT,
  whatsTherePointingBasedSearch,
} from '../utils/';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    logo: {
      maxHeight: '500px',
      marginBottom: '1px',
    },
    paper: {
      margin: theme.spacing(2, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

const About: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_ABOUT} | {APP_TITLE}
        </title>
      </Helmet>
      <div className={classes.paper}>
      
        <Grid item xs={6}>
        <img src={WhatsThereRevised} alt='What is There' className={classes.logo} />
          <Typography component='h4' variant='body2' color='textSecondary'>
            {whatsTherePointingBasedSearch}
          </Typography>
        </Grid>
      </div>
    </>
  );
};

export default About;
