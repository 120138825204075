import React, { FC, ReactElement } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

// constants
import { FOOTER_TEXT, FOOTER_HEIGHT } from '../../utils/constants';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      background: theme.palette.background.paper,
      minHeight: FOOTER_HEIGHT,
    },
    footer: {
      textTransform: 'uppercase',
    },
  })
);

// functional component
const Footer: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavLink
        to="/"
        rel='noreferrer'
        className={classes.footer}
      >
        {FOOTER_TEXT}
      </NavLink>
    </div>
  );
};

export default Footer;
