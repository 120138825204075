import validator from 'validator';

const validators = {
  emailError: (val: string) => {
    if (val.length > 0) {
      return !validator.isEmail(val);
    }
    return false;
  },

  isAcceptablePassword: (string: string) => {
    // 02/09/21 MF: Note that there is a bug in the validator package where the @ symbol is not considered a symbol.
    // https://github.com/validatorjs/validator.js/issues/1563 There is a pending PR that if accepted should
    // fix the issue and allow us to add this to the options: extendSymbols: '@'
    return validator.isStrongPassword(string, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
  },

  isEmail: (string: string) => {
    return validator.isEmail(string);
  },


  phoneError: (val: string) => {
    if (val.length > 0) {
      return !validator.isMobilePhone(val, ['en-US', 'en-CA']);
    }
    return false;
  },

};

export default validators;
