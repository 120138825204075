import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { CssBaseline, Grid, Paper, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Image from '../assets/use-smartphone.jpeg';
import logo from '../assets/logo.png';

// constants
import { APP_TITLE, PAGE_TITLE_CONTACT } from '../utils/constants';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    image: {
      backgroundImage: `url(${Image})`, // image comes from: https://source.unsplash.com/0ou4CVSoINY
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    logo: {
      maxHeight: '150px',
      marginBottom: '16px',
    },
    icon: {
      color: 'inherit',
      textDecoration: 'none',
      size: 'small',
      margin: theme.spacing(8, 4),
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    root: {
      height: '70vh',
    },
  })
);

const Contact: FC<{}> = (): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_CONTACT} | {APP_TITLE}
        </title>
      </Helmet>
      <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt='Logo' className={classes.logo} />
          <Typography component='h3' variant='h6'>
            {"Contact"}
          </Typography>
          <Grid item xs={false} sm={4} md={5} className={classes.icon}>
          <MailIcon />
          <Typography component='h3' variant='h6'>
          
          <a href='mailto:info@whatsthere.io'>info@whatsthere.io</a>
          </Typography>
          </Grid>
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default Contact;
